import React from "react"
import Helmet from 'react-helmet'


export default function Terms () {
	return (
		<>
		<Helmet>
			<title>Terms of Use - Craft Boxing</title>
		</Helmet>
		<iframe title="terms of use" src="https://app.termly.io/document/terms-of-use-for-website/28a944a7-1492-40a2-8050-b2f4a7249082"
		width="100%"
		height="2000px"
		frameBorder="0"  />
		</>
	)
}
